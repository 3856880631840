import React, { useState } from 'react';
import { Stage, Layer, Rect, Transformer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import './css/Artist.css';

// Import images for artists
import halte from '../assets/artists/MichelGAUTHIER/halte.jpg';
import neige from '../assets/artists/MichelGAUTHIER/neige.jpg';
import tholen from '../assets/artists/MichelGAUTHIER/tholen.jpg';
import yser from '../assets/artists/MichelGAUTHIER/yser.jpg';
import insectesColeopteres from '../assets/artists/RoseDESMAISONS/insectes-coleopteres.jpg';
import musiqueEtDanse from '../assets/artists/RoseDESMAISONS/musique-et-danse.jpg';
import toileDAraignee from '../assets/artists/RoseDESMAISONS/toile-d-araignee.jpg';
import zenAmmonite from '../assets/artists/RoseDESMAISONS/zen-ammonite.jpg';
import rainofcolors from '../assets/artists/LanaTIKHONOVA/rain-of-colors.jpg';
import moonstonemusic from '../assets/artists/LanaTIKHONOVA/moonstone-music.jpg';
import morethannight from '../assets/artists/LanaTIKHONOVA/more-than-night.jpg';
import persephone from '../assets/artists/LanaTIKHONOVA/persephone.jpg';

// Artist images for profile pictures
import gauthierArtistPic from '../assets/artists/MichelGAUTHIER/artist.jpg';
import desmaisonsArtistPic from '../assets/artists/RoseDESMAISONS/artist.jpg';
import tikhonovaArtistPic from '../assets/artists/LanaTIKHONOVA/artist.jpg';

const Artist = () => {
  const [selectedArtist, setSelectedArtist] = useState('LanaTIKHONOVA');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [userImageURL, setUserImageURL] = useState('');
  const [userImage] = useImage(userImageURL);
  const [imageDimensions, setImageDimensions] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [rect, setRect] = useState({ x: 50, y: 50, width: 100, height: 100 });
  const [selectedPaintingURL, setSelectedPaintingURL] = useState('');
  const [selectedPainting] = useImage(selectedPaintingURL);
  const [rectHistory, setRectHistory] = useState([]);

  const stageWidth = 600;
  const stageHeight = 400;

  const artistImages = {
    LanaTIKHONOVA: {
      images: [
        { name: "Moonstone Music", src: moonstonemusic, price: "10 000,00 €", dimensions: "200 x 150 cm" },
        { name: "Persephone", src: persephone, price: "7 200,00 €", dimensions: "135 x 100 cm" },
        { name: "Rain of colors", src: rainofcolors, price: "4 850,00 €", dimensions: "100 x 75 cm" },
        { name: "More than night", src: morethannight, price: "1 200,00 €", dimensions: "80 x 60 cm" },
      ],
      bio: "Lana Tikhonova explore des thèmes mythologiques avec une touche contemporaine, mêlant histoire et récits modernes.",
      artistPic: tikhonovaArtistPic,
    },
    MichelGAUTHIER: {
      images: [
        { name: "Halte en Brabant", src: halte, price: "4 500,00 €", dimensions: "41 x 33 cm" },
        { name: "Première Neige", src: neige, price: "4 500,00 €", dimensions: "41 x 33 cm" },
        { name: "Tholen 1802", src: tholen, price: "5 500,00 €", dimensions: "46 x 38 cm" },
        { name: "L'Yser", src: yser, price: "3 720,00 €", dimensions: "37 x 27 cm" },
      ],
      bio: "Michel Gauthier est connu pour ses paysages vivants et ses détails complexes.",
      artistPic: gauthierArtistPic,
    },
    RoseDESMAISONS: {
      images: [
        { name: "Musique et Danse", src: musiqueEtDanse, price: "850,00 €", dimensions: "50 X 50 cm" },
        { name: "Insectes Coléoptères", src: insectesColeopteres, price: "980,00 €", dimensions: "H. 45 cm/l. 45 cm/P. 1.5 cm" },
        { name: "Toile d'araignée", src: toileDAraignee, price: "520,00 €", dimensions: "H. 50 cm/l. 55 cm/P. 1.5 cm" },
        { name: "Zen Ammonite", src: zenAmmonite, price: "1 400,00 €", dimensions: "H. 60 cm/l. 60 cm/P. 1.5 cm" },
      ],
      bio: "L'œuvre de Rose DesMaisons met en valeur la beauté du monde naturel à travers des couleurs vives.",
      artistPic: desmaisonsArtistPic,
    },
  };

  const artists = Object.keys(artistImages);

  const formatArtistName = (name) => {
    return name.replace(/([a-z])([A-Z])/, '$1 $2');
  };

  const handleArtistSelection = (artist) => {
    setSelectedArtist(artist);
    setDropdownOpen(false);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUserImageURL(reader.result);
        setStep(2);
        const img = new window.Image();
        img.onload = () => {
          const scaleWidth = stageWidth / img.width;
          const scaleHeight = stageHeight / img.height;
          const scale = Math.max(scaleWidth, scaleHeight);
          const width = img.width * scale;
          const height = img.height * scale;
          const x = (stageWidth - width) / 2;
          const y = (stageHeight - height) / 2;
          setImageDimensions({ width, height, x, y });
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelectPainting = (paintingURL) => {
    setSelectedPaintingURL(paintingURL);
    setStep(3);
  };

  const updateRect = (newAttrs) => {
    setRectHistory([...rectHistory, rect]);
    setRect(newAttrs);
  };

  const undoLastChange = () => {
    if (rectHistory.length === 0) return;
    setRect(rectHistory[rectHistory.length - 1]);
    setRectHistory(rectHistory.slice(0, -1));
  };

  const resetAll = () => {
    setStep(1);
    setUserImageURL('');
    setSelectedPaintingURL('');
    setRect({ x: 50, y: 50, width: 100, height: 100 });
    setRectHistory([]);
  };

  const RectComponent = ({ shapeProps, isSelected, onSelect, onChange }) => {
    const shapeRef = React.useRef();
    const trRef = React.useRef();

    React.useEffect(() => {
      if (isSelected) {
        const transformerConfig = {
          anchorSize: 10,
          anchorStroke: '#666',
          anchorFill: '#222',
          borderStroke: '#888',
          borderDash: [3, 3],
          anchorCornerRadius: 2,
        };
        trRef.current.setAttrs(transformerConfig);
        trRef.current.nodes([shapeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }, [isSelected]);

    return (
      <>
        <Rect
          onClick={onSelect}
          ref={shapeRef}
          {...shapeProps}
          draggable
          onDragEnd={e =>
            onChange({
              ...shapeProps,
              x: e.target.x(),
              y: e.target.y(),
            })
          }
          onTransformEnd={e => {
            const node = shapeRef.current;
            onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              width: Math.max(5, node.width() * node.scaleX()),
              height: Math.max(5, node.height() * node.scaleY()),
            });
            node.scaleX(1);
            node.scaleY(1);
          }}
        />
        {isSelected && (
          <Transformer
            ref={trRef}
            boundBoxFunc={(oldBox, newBox) => {
              if (newBox.width < 5 || newBox.height < 5) {
                return oldBox;
              }
              return newBox;
            }}
          />
        )}
      </>
    );
  };

  return (
    <div className="artist-widget">
      <div className="left-section">
        <div className="artist-dropdown">
          <button className="artist-dropdown-button" onClick={() => setDropdownOpen(!dropdownOpen)}>
            {formatArtistName(selectedArtist)}
            <span>&#9660;</span>
          </button>
          {dropdownOpen && (
            <div className="artist-dropdown-menu">
              {artists.map((artist) => (
                <div key={artist} className="artist-dropdown-item" onClick={() => handleArtistSelection(artist)}>
                  {formatArtistName(artist)}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="artist-profile">
          <img src={artistImages[selectedArtist].artistPic} alt="Artist" className="artist-pic" />
          <p className="artist-bio">{artistImages[selectedArtist].bio}</p>
        </div>
      </div>
      <div className="middle-section">
        {artistImages[selectedArtist].images.map((image, index) => (
          <div key={index} className="artist-image-container">
            <div className="artist-image-name">{image.name}</div>
            <img src={image.src} alt={`Artwork ${index + 1}`} className="artist-image" />
            <div className="artist-image-details">
              <div className="artist-image-price">{image.price}</div>
              <div className="artist-image-dimensions">{image.dimensions}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="right-section">
        <div className="artdiscovery-container">
          <h2>Discover These Paintings in Your Own Apartment</h2>
          <p>This interactive tool allows you to visualize how various paintings would look in your space. Select a painting, upload a photo of your room, and position the artwork to discover new dimensions of art in your home.</p>
          {step === 1 && (
            <div className="artdiscovery-upload-step">
              <input type="file" onChange={handleImageUpload} accept="image/*" />
            </div>
          )}
          {step === 2 && (
            <div className="artdiscovery-selection-step">
              <h3>Select a painting:</h3>
              <div className="artdiscovery-paintings">
                {artistImages[selectedArtist].images.map((painting, i) => (
                  <img key={i} src={painting.src} alt={`Painting ${i + 1}`} className="artdiscovery-painting" onClick={() => handleSelectPainting(painting.src)} />
                ))}
              </div>
            </div>
          )}
          {step === 3 && (
            <>
              <div className="artdiscovery-canvas-step">
                <Stage width={stageWidth} height={stageHeight}>
                  <Layer>
                    <KonvaImage image={userImage} {...imageDimensions} />
                    {selectedPainting && <KonvaImage image={selectedPainting} {...rect} />}
                    <RectComponent shapeProps={rect} isSelected={true} onSelect={() => {}} onChange={updateRect} />
                  </Layer>
                </Stage>
              </div>
              <div className="artdiscovery-buttons">
                <button className="artdiscovery-start-over-button" onClick={resetAll}>Reset</button>
                <button className="artdiscovery-undo-button" onClick={undoLastChange}>Undo</button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Artist;
