import React, { useState } from 'react';
import logo from '../assets/logos/logo.jpg';
import './css/header.css';
import { Link } from 'react-router-dom';
import useWindowSize from './useWindowSize'; // Ensure the correct path

const Header = ({ scrolling }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const size = useWindowSize();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const isMobile = size.width <= 768;

    return (
        <>
            {isMobile ? (
                <header className="Mobile-header">
                    <div className="mobile-logo">
                        <img src={logo} alt="Logo" style={{ maxHeight: '70px', maxWidth: '100%', height: 'auto', width: 'auto' }} />
                    </div>
                    <div className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <ul className={`mobile-nav-links ${menuOpen ? 'show' : ''}`}>
                        <li><a href="/about">À PROPOS</a></li>
                        <li className="offers-dropdown"><a href="/offers">NOS OFFRES</a>
                            <div className="mobile-dropdown-content offers">
                                <div className="dropdown-section">
                                    <h4>Nos offres actuelles</h4>
                                    <Link to="/Architecture">Architecture</Link>
                                    <Link to="/Interiordesign">Design intérieur</Link>
                                    <Link to="/FengShuiDiagnosis">Diagnostic Feng Shui</Link>
                                    <Link to="/HomeStagingFengShui">Home Staging Feng Shui</Link>
                                    <Link to="/EcoLandScapeOnline">Paysage écologique en ligne</Link>
                                    <Link to="/BioEcoArchitecture">Bio Eco-Architecture</Link>
                                </div>
                            </div>
                        </li>
                        <li><a href="/how-it-works">COMMENT ÇA MARCHE</a></li>
                        <li><a href="/achievements">RÉALISATIONS</a></li>
                        <li><a href="/renovations">RÉNOVATIONS</a></li>
                        <li className="art-dropdown"><Link to="/art">ART</Link>
                            <div className="mobile-dropdown-content art">
                                <div className="dropdown-section mobile-header-artists-grid">
                                    <h4>Artistes créatifs</h4>
                                    <div className="mobile-header-artists-links-grid">
                                        <a href="/Sarah ARENSI">Sarah ARENSI</a>
                                        <a href="/ARMAN">ARMAN</a>
                                        <Link to="/lanatikhonova">Lana TIKHONOVA</Link>
                                        <Link to="/NADDAYELLA">NADDAYELLA</Link>
                                        <a href="/Claire BIETTE">Claire BIETTE</a>
                                        <a href="/Rose DESMAISONS">Rose DESMAISONS</a>
                                        <a href="/Nathalie FOSSE">Nathalie FOSSE</a>
                                        <a href="/Claire BIETTE">Claire BIETTE</a>
                                        <a href="/Michel GAUTHIER">Michel GAUTHIER</a>
                                        <a href="/Joseph GHANEM">Joseph GHANEM</a>
                                        <a href="/Odile JACENKO">Odile JACENKO</a>
                                        <a href="/Vero REATO">Vero REATO</a>
                                        <a href="/Guillaume ROCHE">Guillaume ROCHE</a>
                                        <a href="/Sigvard SCHOU">Sigvard SCHOU</a>
                                        <a href="/Andrew VICARI">Andrew VICARI</a>
                                        <a href="/NADDAYELLA">NEVY de COLLANEV</a>
                                    </div>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Les créations</h4>
                                    <a href="/Sculptures">Sculptures</a>
                                    <a href="/Lithographs">Lithographies</a>
                                    <a href="/Paintings">Peintures</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Matériaux</h4>
                                    <a href="/Steel">Acier</a>
                                    <a href="/Marble">Marbre</a>
                                    <a href="/Cultured concrete">Béton culturel</a>
                                    <a href="/Enamel on Lava">Émail sur lave</a>
                                    <a href="/Acrylic-on-Canvas">Acrylique sur toile</a>
                                    <a href="/Painting-on-tracing-paper">Peinture sur papier calque</a>
                                    <a href="/Paint-on-wood">Peinture sur bois</a>
                                    <a href="/Watercolor">Aquarelle</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>STUDIO NFT</h4>
                                    <a href="/About NFTs">À propos des NFT</a>
                                </div>
                            </div>
                        </li>
                        <li className="shop-dropdown"><a href="/shop">BOUTIQUE</a>
                            <div className="mobile-dropdown-content shop">
                                <div className="dropdown-section">
                                    <h4>BÉTON</h4>
                                    <a href="/Concrete">Béton</a>
                                    <a href="/Lime">Chaux</a>
                                    <a href="/Hybrid-Concrete">Béton hybride</a>
                                </div>

                                <div className="dropdown-section">
                                    <h4>Chaux</h4>
                                    <a href="/Tadelakt">Tadelakt</a>
                                    <a href="/Fine-and-Medium-Grain-Lime">Chaux fine et moyenne granulométrie</a>
                                    <a href="/Paintings">Badigeon de chaux</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Revêtements</h4>
                                    <a href="/Sénipierres">Sénipierres</a>
                                    <a href="/Provence-Decors">Décors de Provence</a>
                                    <a href="/Argile">Argile</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>EFFETS DÉCORATIFS</h4>
                                    <a href="/Sénipierres">Effet minéral mat</a>
                                    <a href="/Mineral-Metal-Effect">Effet métal minéral</a>
                                    <a href="/Crystal">Cristal</a>
                                    <a href="/Diamonds">Diamants</a>
                                    <a href="/Cabaret">Cabaret</a>
                                    <a href="/NewLook">Nouveau look</a>
                                    <a href="/Anthéa">Anthéa</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>COLORATION</h4>
                                    <a href="/Colouring-syringes">Seringues de coloration</a>
                                    <a href="/Pigments">Pigments</a>
                                    <a href="/Color-Dosage">Dosage de couleur</a>
                                    <a href="/Matte-Varnish">Vernis mat</a>
                                    <a href="/Satin-Varnish">Vernis satiné</a>
                                    <a href="/Wax">Cire</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>MEUBLES</h4>
                                    <a href="/Sofas">Sofas</a>
                                    <a href="/Armchairs">Fauteuils</a>
                                    <a href="/Tables">Tables</a>
                                    <a href="/Coffee-Tables">Tables basses</a>
                                    <a href="/Chairs">Chaises</a>
                                    <a href="/Desks">Bureaux</a>
                                    <a href="/Side-tables">Tables d'appoint</a>
                                    <a href="/Beds">Lits</a>
                                    <a href="/Lighting">Éclairage</a>
                                    <a href="/Leisure-Games-Sports">Loisirs - Jeux - Sports</a>
                                    <a href="/Murano">Murano</a>
                                    <a href="/Consol">Console</a>
                                    <a href="/Shelves">Étagères</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>SIGNATURES</h4>
                                    <a href="/Manuel-CANOVAS">Manuel CANOVAS</a>
                                    <a href="/CASUAL">CASUAL</a>
                                    <a href="/ESEDRA">ESEDRA</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>CRÉATEURS — DESIGNERS</h4>
                                    <a href="/ARCHIBUILD-TaniosHAMAM">ARCHIBUILD — Tanios HAMAM</a>
                                    <a href="/NathalieFOSSE">Nathalie FOSSE</a>
                                    <a href="/MITHKA-DESIGN-MadeleineHOFFMANN">MITHKA DESIGN — Madeleine HOFFMANN</a>
                                    <a href="/PÉPITE-DE-LAVE-RoseDESMAISONS">PÉPITE DE LAVE — Rose DESMAISONS</a>
                                    <a href="/DECORENKO-OdileJACENKO">DECORENKO — Odile JACENKO</a>
                                    <a href="/OlivierTOULOUSE">Olivier TOULOUSE</a>
                                    <a href="/FlorenceBOUREL">Florence BOUREL</a>
                                    <a href="/CyrilGORIN">Cyril GORIN</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </header>
            ) : (
                <header className={`App-header ${scrolling ? 'scrolled' : ''}`}>
                    <div className="logo">
                        <img src={logo} alt="Logo" style={{ maxHeight: '70px', maxWidth: '100%', height: 'auto', width: 'auto' }} />
                    </div>
                    <ul className="header-nav-links">
                        <li><a href="/about">À PROPOS</a></li>
                        <li className="offers-dropdown"><a href="/offers">NOS OFFRES</a>
                            <div className="dropdown-content offers">
                                <div className="dropdown-section">
                                    <h4>Nos offres actuelles</h4>
                                    <Link to="/Architecture">Architecture</Link>
                                    <Link to="/Interiordesign">Design intérieur</Link>
                                    <Link to="/FengShuiDiagnosis">Diagnostic Feng Shui</Link>
                                    <Link to="/HomeStagingFengShui">Home Staging Feng Shui</Link>
                                    <Link to="/EcoLandScapeOnline">Paysage écologique en ligne</Link>
                                    <Link to="/BioEcoArchitecture">Bio Eco-Architecture</Link>
                                </div>
                            </div>
                        </li>
                        <li><a href="/how-it-works">COMMENT ÇA MARCHE</a></li>
                        <li><a href="/achievements">RÉALISATIONS</a></li>
                        <li><a href="/renovations">RÉNOVATIONS</a></li>
                        <li className="art-dropdown"><Link to="/art">ART</Link>
                            <div className="dropdown-content art">
                                <div className="dropdown-section artists-grid">
                                    <h4>Artistes créatifs</h4>
                                    <div className="artists-links-grid">
                                        <a href="/Sarah ARENSI">Sarah ARENSI</a>
                                        <a href="/ARMAN">ARMAN</a>
                                        <Link to="/lanatikhonova">Lana TIKHONOVA</Link>
                                        <Link to="/NADDAYELLA">NADDAYELLA</Link>
                                        <a href="/Claire BIETTE">Claire BIETTE</a>
                                        <a href="/Rose DESMAISONS">Rose DESMAISONS</a>
                                        <a href="/Nathalie FOSSE">Nathalie FOSSE</a>
                                        <a href="/Claire BIETTE">Claire BIETTE</a>
                                        <a href="/Michel GAUTHIER">Michel GAUTHIER</a>
                                        <a href="/Joseph GHANEM">Joseph GHANEM</a>
                                        <a href="/Odile JACENKO">Odile JACENKO</a>
                                        <a href="/Vero REATO">Vero REATO</a>
                                        <a href="/Guillaume ROCHE">Guillaume ROCHE</a>
                                        <a href="/Sigvard SCHOU">Sigvard SCHOU</a>
                                        <a href="/Andrew VICARI">Andrew VICARI</a>
                                        <a href="/NADDAYELLA">NEVY de COLLANEV</a>
                                    </div>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Les créations</h4>
                                    <a href="/Sculptures">Sculptures</a>
                                    <a href="/Lithographs">Lithographies</a>
                                    <a href="/Paintings">Peintures</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Matériaux</h4>
                                    <a href="/Steel">Acier</a>
                                    <a href="/Marble">Marbre</a>
                                    <a href="/Cultured concrete">Béton culturel</a>
                                    <a href="/Enamel on Lava">Émail sur lave</a>
                                    <a href="/Acrylic-on-Canvas">Acrylique sur toile</a>
                                    <a href="/Painting-on-tracing-paper">Peinture sur papier calque</a>
                                    <a href="/Paint-on-wood">Peinture sur bois</a>
                                    <a href="/Watercolor">Aquarelle</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>STUDIO NFT</h4>
                                    <a href="/About NFTs">À propos des NFT</a>
                                </div>
                            </div>
                        </li>
                        <li className="shop-dropdown"><a href="/shop">BOUTIQUE</a>
                            <div className="dropdown-content shop">
                                <div className="dropdown-section">
                                    <h4>BÉTON</h4>
                                    <a href="/Concrete">Béton</a>
                                    <a href="/Lime">Chaux</a>
                                    <a href="/Hybrid-Concrete">Béton hybride</a>
                                </div>

                                <div className="dropdown-section">
                                    <h4>Chaux</h4>
                                    <a href="/Tadelakt">Tadelakt</a>
                                    <a href="/Fine-and-Medium-Grain-Lime">Chaux fine et moyenne granulométrie</a>
                                    <a href="/Paintings">Badigeon de chaux</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>Revêtements</h4>
                                    <a href="/Sénipierres">Sénipierres</a>
                                    <a href="/Provence-Decors">Décors de Provence</a>
                                    <a href="/Argile">Argile</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>EFFETS DÉCORATIFS</h4>
                                    <a href="/Sénipierres">Effet minéral mat</a>
                                    <a href="/Mineral-Metal-Effect">Effet métal minéral</a>
                                    <a href="/Crystal">Cristal</a>
                                    <a href="/Diamonds">Diamants</a>
                                    <a href="/Cabaret">Cabaret</a>
                                    <a href="/NewLook">Nouveau look</a>
                                    <a href="/Anthéa">Anthéa</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>COLORATION</h4>
                                    <a href="/Colouring-syringes">Seringues de coloration</a>
                                    <a href="/Pigments">Pigments</a>
                                    <a href="/Color-Dosage">Dosage de couleur</a>
                                    <a href="/Matte-Varnish">Vernis mat</a>
                                    <a href="/Satin-Varnish">Vernis satiné</a>
                                    <a href="/Wax">Cire</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>MEUBLES</h4>
                                    <a href="/Sofas">Sofas</a>
                                    <a href="/Armchairs">Fauteuils</a>
                                    <a href="/Tables">Tables</a>
                                    <a href="/Coffee-Tables">Tables basses</a>
                                    <a href="/Chairs">Chaises</a>
                                    <a href="/Desks">Bureaux</a>
                                    <a href="/Side-tables">Tables d'appoint</a>
                                    <a href="/Beds">Lits</a>
                                    <a href="/Lighting">Éclairage</a>
                                    <a href="/Leisure-Games-Sports">Loisirs - Jeux - Sports</a>
                                    <a href="/Murano">Murano</a>
                                    <a href="/Consol">Console</a>
                                    <a href="/Shelves">Étagères</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>SIGNATURES</h4>
                                    <a href="/Manuel-CANOVAS">Manuel CANOVAS</a>
                                    <a href="/CASUAL">CASUAL</a>
                                    <a href="/ESEDRA">ESEDRA</a>
                                </div>
                                <div className="dropdown-section">
                                    <h4>CRÉATEURS — DESIGNERS</h4>
                                    <a href="/ARCHIBUILD-TaniosHAMAM">ARCHIBUILD — Tanios HAMAM</a>
                                    <a href="/NathalieFOSSE">Nathalie FOSSE</a>
                                    <a href="/MITHKA-DESIGN-MadeleineHOFFMANN">MITHKA DESIGN — Madeleine HOFFMANN</a>
                                    <a href="/PÉPITE-DE-LAVE-RoseDESMAISONS">PÉPITE DE LAVE — Rose DESMAISONS</a>
                                    <a href="/DECORENKO-OdileJACENKO">DECORENKO — Odile JACENKO</a>
                                    <a href="/OlivierTOULOUSE">Olivier TOULOUSE</a>
                                    <a href="/FlorenceBOUREL">Florence BOUREL</a>
                                    <a href="/CyrilGORIN">Cyril GORIN</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </header>
            )}
        </>
    );
};

export default Header;
