import React, { useEffect, useState } from 'react';
import "@babylonjs/core/Debug/debugLayer";
import "@babylonjs/inspector";
import "@babylonjs/loaders/glTF";
import {
  Engine,
  Scene,
  UniversalCamera,
  Vector3,
  SceneLoader,
  HemisphericLight,
  DirectionalLight,
  ActionManager,
  ExecuteCodeAction,
} from "@babylonjs/core";

import galleryGLB from "../assets/gallery/gallery6.glb";
import artworks from './artworks';  // Import the artworks configuration

function Art() {
  const [cameraPosition, setCameraPosition] = useState({ x: 0, y: 0, z: 0 });
  const [currentArtwork, setCurrentArtwork] = useState({});
  const fixedYPosition = 2.5;  // Define a constant Y position

  useEffect(() => {
    // Create canvas and set its styles to ensure full screen
    const canvas = document.createElement("canvas");
    canvas.style.position = "fixed";
    canvas.style.top = 0;
    canvas.style.left = 0;
    canvas.style.width = "100%";
    canvas.style.height = "100vh";
    canvas.style.zIndex = -1;
    canvas.id = "gameCanvas";
    document.body.appendChild(canvas);

    // Set body styles to ensure no scroll and full screen
    document.body.style.margin = 0;
    document.body.style.overflow = "hidden";

    const engine = new Engine(canvas, true);
    const scene = new Scene(engine);
    scene.collisionsEnabled = true;

    const camera = new UniversalCamera("camera", new Vector3(-35.55, fixedYPosition, -18.65), scene);  // Updated initial position
    camera.attachControl(canvas, true);
    camera.speed = 0.25;  // Adjust camera speed for mobile
    camera.angularSensibility = 5000;  // Adjust sensitivity for mobile
    camera.minZ = 0.1;
    camera.applyGravity = false;
    camera.checkCollisions = true;
    camera.ellipsoid = new Vector3(1, 1, 1);

    const hemiLight = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), scene);
    hemiLight.intensity = 0.6;

    const dirLight = new DirectionalLight("dirLight", new Vector3(-1, -2, -1), scene);
    dirLight.position = new Vector3(-12.54, 29.37, -79.41);
    dirLight.intensity = 0.7;

    engine.displayLoadingUI();

    SceneLoader.ImportMeshAsync("", galleryGLB, "").then(function (result) {
      engine.hideLoadingUI();
      result.meshes.forEach(mesh => {
        mesh.checkCollisions = true; // Ensure meshes are collidable

        if (artworks[mesh.name]) {  // Check if there is a configuration for the mesh
          console.log(`Configuring action for ${mesh.name}`);
          mesh.actionManager = new ActionManager(scene);
          mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, () => {
            console.log(`Mesh ${mesh.name} clicked`);
            setCurrentArtwork(artworks[mesh.name]);  // Set the current artwork details
          }));
        }
      });
    });

    engine.runRenderLoop(() => {
      camera.position.y = fixedYPosition;  // Fix the Y position
      scene.render();
      setCameraPosition({
        x: parseFloat(camera.position.x.toFixed(2)),
        y: parseFloat(camera.position.y.toFixed(2)),
        z: parseFloat(camera.position.z.toFixed(2))
      });
    });

    window.addEventListener('resize', () => {
      engine.resize();
    });

    return () => {
      engine.dispose();
      document.body.removeChild(canvas);
      // Reset body styles
      document.body.style.margin = "";
      document.body.style.overflow = "";
    };
  }, []);

  const handleClose = () => {
    setCurrentArtwork({});  // Clear the current artwork to close the popup
  };

  return (
    <>
      <div style={{
        position: 'fixed',
        top: '10px',
        right: '10px',
        padding: '5px 10px',
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: 'white',
        borderRadius: '5px',
        zIndex: 1000,
      }}>
        X: {cameraPosition.x} | Y: {cameraPosition.y} | Z: {cameraPosition.z}
      </div>
      {currentArtwork.image && (
        <div style={{
          position: 'fixed',
          bottom: '10px',
          left: '10px',
          width: '300px',  // Fixed width
          maxWidth: '90%',  // Ensure it fits on mobile screens
          padding: '5px 10px',
          backgroundColor: 'rgba(0,0,0,0.8)',
          color: 'white',
          borderRadius: '5px',
          zIndex: 1000,
          textAlign: 'center', // This centers all the text
        }}>
          <button onClick={handleClose} style={{
            position: 'absolute',
            top: 0,
            right: '10px',
            border: 'none',
            background: 'transparent',
            color: 'white',
            fontSize: '24px',
            cursor: 'pointer'
          }}>×</button>
          <div>{currentArtwork.artistName}</div>
          <div style={{ marginTop: '5px', fontSize: '16px', fontStyle: 'italic' }}>{currentArtwork.paintingDescription}</div>
          <img src={currentArtwork.image} alt={currentArtwork.paintingDescription} style={{ width: '100%', marginTop: '10px', maxHeight: '200px', objectFit: 'contain' }} />
          <div style={{ marginTop: '5px' }}>{currentArtwork.currentDescription}</div>
        </div>
      )}
    </>
  );
}

export default Art;
