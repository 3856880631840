const artworks = {
    "Cube.067_primitive1": {
      artistName: "Lana TIKHONOVA",
      paintingDescription: "Moonstone Music",
      currentDescription: "Price: €10,000.00 - Size: 200 x 150 cm",
      image: require("../Art/gallery/moonstone-music.jpg")  // Adjust the path as necessary
    },
    "Cube.052_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Something more than night",
        currentDescription: "Price: €1 200,00 - Size: 80 x 60 cm",
        image: require("../Art/gallery/more-than-night.jpg")  // Adjust the path as necessary
      },

      "Cube.074_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Checkmate",
        currentDescription: "Price: €1 380,00 - Size: 80 x 60 cm",
        image: require("../Art/gallery/checkmate.jpg")  // Adjust the path as necessary
      },

      "Cube.042_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Troublemaker",
        currentDescription: "Price: €1 650,00 - Size: 80 x 80 cm",
        image: require("../Art/gallery/troublemaker.jpg")  // Adjust the path as necessary
      },

      "Cube.068_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Discern the limits of darkness",
        currentDescription: "Price: €2 200,00 - Size: 80 x 80 cm",
        image: require("../Art/gallery/limits-of-darkness.jpg")  // Adjust the path as necessary
      },

      "Cube.069_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Nox",
        currentDescription: "Price: €5 000,00 - Size: 120 x 120 cm",
        image: require("../Art/gallery/nox.jpg")  // Adjust the path as necessary
      },

      "Cube.013_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Brown Sugar",
        currentDescription: "Price: €2 000,00 - Size: 80 x 80 cm",
        image: require("../Art/gallery/brown-sugar.jpg")  // Adjust the path as necessary
      },

      "Cube.063_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Smoke in the mirror of snow",
        currentDescription: "Price: €750,00 - Size: 50 x 50 cm",
        image: require("../Art/gallery/smoke-in-the-mirror-of-snow.jpg")  // Adjust the path as necessary
      },

      "Cube.070_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "What if",
        currentDescription: "Price: €2 450,00 - Size: 100 x 100 cm",
        image: require("../Art/gallery/what-if.jpg")  // Adjust the path as necessary
      },

      "Cube.072_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Turn to blues",
        currentDescription: "Price: €750,00 - Size: 50 x 50 cm",
        image: require("../Art/gallery/turn-to-blues.jpg")  // Adjust the path as necessary
      },

      "Cube.073_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Rose",
        currentDescription: "Price: €2 200,00 - Size: 80 x 80 cm",
        image: require("../Art/gallery/rose.jpg")  // Adjust the path as necessary
      },

      "Cube.058_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Porcelain cup of the day",
        currentDescription: "Price: €500,00 - Size: 50 x 50 cm",
        image: require("../Art/gallery/porcelain-cup-of-the-day.jpg")  // Adjust the path as necessary
      },

      "Cube.060_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Crystal silence",
        currentDescription: "Price: €500,00 - Size: 50 x 50 cm",
        image: require("../Art/gallery/crystal-silence.jpg")  // Adjust the path as necessary
      },

      "Cube.057_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Touch of the moon",
        currentDescription: "Price: €1 250,00 - Size: 60 x 80 cm",
        image: require("../Art/gallery/touch-of-the-moon.jpg")  // Adjust the path as necessary
      },

      "Cube.017_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Night Overflow",
        currentDescription: "Price: €1 000,00 - Size: 100 x 120 cm",
        image: require("../Art/gallery/night-overflow.jpg")  // Adjust the path as necessary
      },

      "Cube.034_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "City in Foxy Clothes",
        currentDescription: "Price: €700,00 - Size: 50 x 70 cm",
        image: require("../Art/gallery/city-in-foxy-clothes.jpg")  // Adjust the path as necessary
      },

      "Cube.056_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "White Moon Sail",
        currentDescription: "Price: €900,00 - Size: 70 x 120 cm",
        image: require("../Art/gallery/white-moon-sail.jpg")  // Adjust the path as necessary
      },

      "Cube.071_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Unwrap me",
        currentDescription: "Price: €2 450,00 - Size: 100 x 100 cm",
        image: require("../Art/gallery/unwrap-me.jpg")  // Adjust the path as necessary
      },

      "Cube.019_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Prague Staromestska Water Tower",
        currentDescription: "Price: €1 000,00 - Size: 70 x 100 x 2cm",
        image: require("../Art/gallery/prague-staromestska-water-tower.jpg")  // Adjust the path as necessary
      },

      "Cube.055_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Colorful side of going nuts",
        currentDescription: "Price: €1 650,00 - Size: 80 x 80 cm",
        image: require("../assets/artists/LanaTIKHONOVA/colorful-side-of-going-nuts.jpg")  // Adjust the path as necessary
      },

      "Cube.054_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Dancing with wolves",
        currentDescription: "Price: €1 650,00 - Size: 120 x 120cm",
        image: require("../assets/artists/LanaTIKHONOVA/dancing-with-wolves.jpg")  // Adjust the path as necessary
      },

      "Cube.041_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Falling in gold",
        currentDescription: "Price: €4 700,00 - Size: 80 x 120cm",
        image: require("../assets/artists/LanaTIKHONOVA/falling-in-gold.jpg")  // Adjust the path as necessary
      },

      "Cube.051_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Enchanting Rain of Colors",
        currentDescription: "Price: Sold - Size: 100 x 75cm",
        image: require("../assets/artists/LanaTIKHONOVA/rain-of-colors.jpg")  // Adjust the path as necessary
      },

      "Cube.049_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "Redline",
        currentDescription: "Price: €24 000,00 - Size: 250 x 200cm",
        image: require("../assets/artists/LanaTIKHONOVA/redline.jpg")  // Adjust the path as necessary
      },

      "Cube.050_primitive1": {
        artistName: "Lana TIKHONOVA",
        paintingDescription: "PERSEPHONE",
        currentDescription: "Price: Sold - Size: 135 x 100cm",
        image: require("../assets/artists/LanaTIKHONOVA/persephone.jpg")  // Adjust the path as necessary
      },

      "Cube.044_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "La Prairie Jaune",
        currentDescription: "Price: €3 000,00 - Size: 193 x 185cm",
        image: require("../assets/artists/OdileJACENKO/La-Prairie-Jaune.png")  // Adjust the path as necessary
      },

      "Cube.043_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "Le cerisier",
        currentDescription: "Price: €3 000,00 - Size: 200 x 150cm",
        image: require("../assets/artists/OdileJACENKO/Le-cerisier.png")  // Adjust the path as necessary
      },

      "Cube.080_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les Pèches",
        currentDescription: "Price: €225,00 - Size: 40 x 40cm",
        image: require("../assets/artists/OdileJACENKO/Les-Pèches.png")  // Adjust the path as necessary
      },

      "Cube.078_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "La Clairière",
        currentDescription: "Price: €1 200,00 - Size: 197 x 78cm",
        image: require("../assets/artists/OdileJACENKO/La-Clairière.png")  // Adjust the path as necessary
      },

      "Cube.040_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "Le Potiron",
        currentDescription: "Price: €750,00 - Size: 50 x 32cm",
        image: require("../assets/artists/OdileJACENKO/Le-Potiron.png")  // Adjust the path as necessary
      },

      "Cube.076_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les Cerises",
        currentDescription: "Price: €900,00 - Size: 64 x 40cm",
        image: require("../assets/artists/OdileJACENKO/Les-Cerises.png")  // Adjust the path as necessary
      },

      "Cube.075_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les volets bleus",
        currentDescription: "Price: €3 150,00 - Size: 250 x 200cm",
        image: require("../assets/artists/OdileJACENKO/Les-volets-bleus.png")  // Adjust the path as necessary
      },

      "Cube_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "Ma Petite Campagne",
        currentDescription: "Price: €3 000,00 - Size: 200 x 163cm",
        image: require("../assets/artists/OdileJACENKO/Ma-Petite-Campagne.png")  // Adjust the path as necessary
      },

      "Cube.077_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les Prunes",
        currentDescription: "Price: €225,00 - Size: 40 x 40cm",
        image: require("../assets/artists/OdileJACENKO/Les-Prunes.png")  // Adjust the path as necessary
      },

      "Cube.079_primitive1": {
        artistName: "Odile JACENKO",
        paintingDescription: "Les Navets",
        currentDescription: "Price: €225,00 - Size: 40 x 40cm",
        image: require("../assets/artists/OdileJACENKO/Les-Navets.png")  // Adjust the path as necessary
      },

      "Cube.081_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Chaleur bleue",
        currentDescription: "Price: €2 000,00 - Size: 50 x 50cm",
        image: require("../assets/artists/Naddayella/Chaleur-bleue.png")  // Adjust the path as necessary
      },

      "Cube.082_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Paix",
        currentDescription: "Price: €1 000,00 - Size: 29 x 29cm",
        image: require("../assets/artists/Naddayella/Paix.png")  // Adjust the path as necessary
      },

      "Cube.083_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Liberté, féminité, créativité",
        currentDescription: "Price: €450,00 - Size: 20 x 20cm",
        image: require("../assets/artists/Naddayella/Liberty.png")  // Adjust the path as necessary
      },

      "Cube.084_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Tranquility",
        currentDescription: "Price: €2 000,00 - Size: 50 x 50cm",
        image: require("../assets/artists/Naddayella/Tranquility.png")  // Adjust the path as necessary
      },

      "Cube.085_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Explosion fleurie",
        currentDescription: "Price: €1 300,00 - Size: 27 x 21cm",
        image: require("../assets/artists/Naddayella/Explosion-fleurie.png")  // Adjust the path as necessary
      },

      "Cube.086_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Creeping Berries",
        currentDescription: "Price: €1 500,00 - Size: 30 x 30cm",
        image: require("../assets/artists/Naddayella/Creeping-Berries.png")  // Adjust the path as necessary
      },

      "Cube.087_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Yellow Aquatic.png",
        currentDescription: "Price: €1 000,00 - Size: 29 x 29cm",
        image: require("../assets/artists/Naddayella/Yellow-Aquatic.png")  // Adjust the path as necessary
      },

      "Cube.088": {
        artistName: "Naddayella",
        paintingDescription: "Tempête de soleil",
        currentDescription: "Price: €950,00 - Size: 21 x 29cm",
        image: require("../assets/artists/Naddayella/Tempête-de-soleil.png")  // Adjust the path as necessary
      },

      "Cube.089_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Goodbye!",
        currentDescription: "Price: €1 500,00 - Size: 30 x 30cm",
        image: require("../assets/artists/Naddayella/Goodbye.png")  // Adjust the path as necessary
      },

      "Cube.090_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Blue Spring",
        currentDescription: "Price: €800,00 - Size: 21 x 29cm",
        image: require("../assets/artists/Naddayella/Blue-Spring.png")  // Adjust the path as necessary
      },

      "Cube.092_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Ocean Vegetal",
        currentDescription: "Price: €950,00 - Size: 21 x 29",
        image: require("../assets/artists/Naddayella/Ocean-Vegetal.png")  // Adjust the path as necessary
      },

      "Cube.091_primitive1": {
        artistName: "Naddayella",
        paintingDescription: "Yellow Spring",
        currentDescription: "Price: €450,00 - Size: 21 x 29cm",
        image: require("../assets/artists/Naddayella/Yellow-Spring.png")  // Adjust the path as necessary
      },
    // Add more entries here for other artworks
  };

  
  export default artworks;