import React from 'react';
import './css/MenuSplit.css';
import video1 from '../assets/menusplit/video1.mp4';
import video2 from '../assets/menusplit/video2.mp4';
import video3 from '../assets/menusplit/video3.mp4';

class MenuSplit extends React.Component {
  componentDidMount() {
    const containers = document.querySelectorAll("#menusplit header > div");

    containers.forEach(container => {
      container.addEventListener('mouseenter', () => {
        container.querySelector('video').play();
      });
      container.addEventListener('mouseleave', () => {
        container.querySelector('video').pause();
      });
    });
  }

  render() {
    return (
      <div id="menusplit">
        <header>
          <div>
            <video src={video1} muted loop></video>
            <div className="text">
              <p className="sub-heading">
                <b>Agence Bostyl</b>&#47;
                <span>Paris</span>
              </p>
              <h1 className="title">
                Architecture d'interieur
              </h1>
              <p>
                Une nouvelle approche qui allie l’architecture intérieure, le respect de l'environnement et le bien-être global.
              </p>
              <a href="#" className="article-link">
                La boutique
              </a>
            </div>
          </div>
          <div>
            <video src={video2} muted loop></video>
            <div className="text">
              <p className="sub-heading">
                <b>Agence Bostyl</b>&#47;
                <span>Paris</span>
              </p>
              <h1 className="title">
                Réalisations
              </h1>
              <p>
                Bénéficiez de notre expérience dans la conception et le suivi de chantier de villas, maisons individuelles, extensions, architecture intérieure, appartements, boutiques, aménagement paysager, mobilier...
              </p>
              <a href="#" className="article-link">
                Nos réalisations
              </a>
            </div>
          </div>
          <div>
            <video src={video3} muted loop></video>
            <div className="text">
              <p className="sub-heading">
                <b>Agence Bostyl</b>&#47;
                <span>Paris</span>
              </p>
              <h1 className="title">
                Galerie d'art
              </h1>
              <p>
                Nos architectes, architectes d'intérieur, designers, ingénieurs, experts en Feng Shui et home staging mettent leur savoir faire à votre service et toute leur expérience dans la réalisation de vos projets.
              </p>
              <a href="#" className="article-link">
                La galerie
              </a>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default MenuSplit;
